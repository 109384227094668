import { AnimateSharedLayout } from 'framer-motion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShippingSameAsBilling } from '../../../../store/reducers/slices/checkout'
import { setBillingAndShippingAddress } from '../../../../store/thunks/checkout/shipping-addresses'
import { withNullErrorBoundary } from '../../../../utils'
import FormCheckboxOption from '../../../form/form-checkbox-option'
import { motion } from '../../../motion'
import AddressSelector from './address-selector'
import BillingAddress from './billing-address'

const ShippingAddress = () => {
  const isNew = useSelector(state => state.addresses.newUser)
  const dispatch = useDispatch()
  const shippingAddress = useSelector(state => state.checkout.form.shippingAddressId)
  const billingAddress = useSelector(state => state.checkout.form.billingAddressId)
  const checked = useSelector(state => state.checkout.form.shippingSameAsBilling)

  const onChange = (val) => {
    dispatch(setShippingSameAsBilling(val))

    if (val && (shippingAddress !== billingAddress)) {
      dispatch(setBillingAndShippingAddress(shippingAddress))
    }
  }

  return (
    <AnimateSharedLayout>
      <motion.label layout className="label">
        {!isNew ? (
          'Use a saved delivery address:'
        ) : (
          'Shipping address:'
        )}
      </motion.label>

      <motion.div layout className="col-12">
        <AddressSelector type={'shipping'} />
      </motion.div>

      <motion.div layout className="col-12">
        <FormCheckboxOption checked={checked} onChange={onChange} type="checkbox" containerClass="billing-address-wrp" value="Billing address same as shipping address" uniqueName="shippingSameAsBilling" className="form-check">
          {({ id }) => (
            <label className="form-check-label confirm" htmlFor={id}>
                Billing address same as shipping address
            </label>
          )}
        </FormCheckboxOption>
      </motion.div>

      <motion.div layout className="col-12">
        <BillingAddress />
      </motion.div>
    </AnimateSharedLayout>
  )
}

export default withNullErrorBoundary(ShippingAddress)
