import { withErrorBoundary } from 'react-error-boundary'

export const stagingUrl = (path) => `//api.cormie.atomix.xyz/${path}`

export const debounce = (func, wait, immediate) => {
  var timeout
  return function() {
    var context = this, args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const storage = typeof window !== 'undefined' && navigator.cookieEnabled
  ? window.localStorage
  : {
    getItem: () => {},
    removeItem: (_key) => {},
    setItem: (_key, _value) => {},
  }

const fallbackRender = ({ error }) => {
  console.error(error)
  const sentry = global.Sentry

  if (sentry) {
    sentry.configureScope((scope) => {
      scope.setExtra('scope', typeof window !== 'undefined' ? 'browser' : 'server')
    })
    sentry.captureException(error)
  }

  return null
}

export const productVariantTextGeneration = (variants) => {
  let content = variants.map(variant => variant.title).map(x=>x[0]).join(', ')
  return content
}

export const withNullErrorBoundary = (Component, props) =>
  withErrorBoundary(Component, { fallbackRender, ...props })
