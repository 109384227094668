import { createStateSyncMiddleware } from 'redux-state-sync'
import { isSsr } from '../../utils/is-ssr'
import { nullMiddleware } from './null'

export const syncMiddleware = isSsr() ? nullMiddleware : createStateSyncMiddleware({
  predicate: action => ![ // Blacklist array
    /^persist/,
    /^location/,
    /^cart\/(openCart|closeCart)$/,
    /^checkout/,
  ].some(regex => regex.test(action.type)),
})
