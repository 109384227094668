import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import AddressFragment from '../../../components/user-accounts/get-addresses'
import { setBillingAddress, setBillingAndShippingAddress, setShippingAddress } from '../checkout/shipping-addresses'

// eslint-disable-next-line no-unused-vars
export const createOrUpdateAddress = createAsyncThunk('account/createAddress', async ({ isShippingAddress, isBillingAddress, isBillingAndShippingAddress, uuid, ...variables }, api) => {
  const { data } = await client.mutate({
    mutation: CREATE_OR_ADD_ADDRESS,
    variables,
  })

  const newAddressId = data.updateMe.customer.addresses[data.updateMe.customer.addresses.length - 1].id

  if (isShippingAddress) {
    api.dispatch(setShippingAddress(newAddressId))
  }

  if (isBillingAddress) {
    api.dispatch(setBillingAddress(newAddressId))
  }

  if (isBillingAndShippingAddress) {
    api.dispatch(setBillingAndShippingAddress(newAddressId))
  }

  return data.updateMe.customer.addresses
})

const CREATE_OR_ADD_ADDRESS = gql`
  ${AddressFragment}

  mutation CreateOrAddAddress(
    $id: Int,
    $firstName: String,
    $lastName: String,
    $title: String,
    $countryCode: String,
    $addressLine1: String,
    $addressLine2: String,
    $locality: String,
    $administrativeArea: String,
    $postalCode: String
  ) {
    updateMe(addresses:[
      {
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        title: $title,
        addressLine1: $addressLine1,
        addressLine2: $addressLine2,
        locality: $locality,
        countryCode: $countryCode,
        administrativeArea: $administrativeArea,
        postalCode: $postalCode,
      }
    ]) {
      customer {
        addresses {
          ...address
        }
      }
    }
  }
`
