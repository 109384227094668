import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { withNullErrorBoundary } from '../../../../utils'
import { motion } from '../../../motion'
import AddressSelector from './address-selector'

const BillingAddress = () => {
  const show = useSelector(state => !state.checkout.form.shippingSameAsBilling)

  return (
    <motion.div>
      <AnimatePresence initial={false}>
        {show && (
          <motion.div
            layout
            key="billing-address"
            variants={{
              open: { height: 'auto', opacity: 1 },
              closed: { height: 0, opacity: 0 },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ type: 'spring', stiffness: 200, damping: 25 }}
          >
            <div
              className="address--billing"
            >
              <AddressSelector type={'billing'} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>

  )
}

export default withNullErrorBoundary(BillingAddress)
