import { store } from '../store'
import { handlers } from './handlers'

export const fire = (event, data = {}) => {
  let state
  try {
    state = store.getState()
  } catch (e) {
    //
  }

  handlers.forEach(({ format = (event, data) => data, handle = () => null } = {}) => {
    handle(event, format(event, data), state)
  })
}
