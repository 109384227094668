import { gql } from 'apollo-boost'
import AddressFragment from '../../components/user-accounts/get-addresses'
import { PaymentSourcesFragment } from './payment-sources-fragment'

export const UserFragment = gql`
  ${AddressFragment}
  ${PaymentSourcesFragment}

  fragment UserFragment on CormieUserInterface {
    username
    email
    status
    firstName
    lastName
    fullName
    token(remember: true)
    customer {
      primaryShippingAddress {
        id
      }
      primaryBillingAddress {
        id
      }
      addresses {
        ...address
      }
      paymentSources {
        ...PaymentSourcesFragment
      }
    }
  }
`
