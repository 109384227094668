import React from 'react'
import { useSelector } from 'react-redux'
import { withNullErrorBoundary } from '../../../../utils'
import FormCheckoutRadioOption from '../../../form/form-checkout-radio-option'

const ShippingMethods = () => {
  const loaded = useSelector(state => state.checkout.loaded)
  const shippingMethods = useSelector(state => state.checkout.data.checkout?.availableShippingMethods)
  const selectedMethod = useSelector(state => state.checkout.data.checkout?.shippingMethodHandle)
  const { totalShippingCost } = useSelector(state => state.cart)

  return loaded && shippingMethods.map(({ handle, name, shippingRules, priceForOrder }) => {
    return (
      <FormCheckoutRadioOption checked={selectedMethod === handle} key={handle} className="form-check" value="express" uniqueName={`checkout-delivery-${handle}`} groupName="checkout-delivery">
        <div className="label-data">
          {name}
          <span>{shippingRules[0]?.description}</span>
        </div>
        <div className="right"><sup>$</sup>{totalShippingCost ? parseFloat(priceForOrder).toFixed(2) : '0.00'}</div>
      </FormCheckoutRadioOption>
    )
  })
}

export default withNullErrorBoundary(ShippingMethods)
