import { gql } from 'apollo-boost'

export const PaymentSourcesFragment = gql`
  fragment PaymentSourcesFragment on PaymentSourceInterface {
    id
    description
    card {
      brand
      exp_year
      exp_month
      last4
      country
    }
  }
`

export const UserPaymentSourcesFragment = gql`
  ${PaymentSourcesFragment}

  fragment UserPaymentSourcesFragment on CormieUserInterface {
    customer {
      paymentSources {
        ...PaymentSourcesFragment
      }
    }
  }
`
