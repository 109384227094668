import { createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../../apollo/client'
import { gql } from 'apollo-boost'
import { PaymentSourcesFragment } from '../../../apollo/fragments/payment-sources-fragment'

export const guestCheckout = createAsyncThunk('checkout/guest', async ({ email, firstName, lastName }, api) => {
  const number = api.getState().cart.number

  const customer = { email, firstName, lastName }

  const { data } = await client.mutate({
    mutation: GUEST_MUTATION,
    variables: {
      number,
      customer,
    },
  })

  return data.updateCart
})

const GUEST_MUTATION = gql`
  ${PaymentSourcesFragment}

  mutation GuestCheckoutMutation(
    $number: ID!,
    $customer: CustomerInput!,
  ) {
    updateCart(
      number: $number,
      customer: $customer,
    ) {
      customer {
        email
        user {
          firstName
          lastName
        }
        paymentSources {
          ...PaymentSourcesFragment
        }
      }
    }
  }
`

