import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import CartFragment from '../../../apollo/fragments/cart-fragment'
import { addressSchema } from '../../../components/checkout/checkout-step-2'

export const submit = createAsyncThunk('checkout/submit', async ({ elements, stripe }, api) => {
  let { savePaymentSource, paymentSourceId } = api.getState().checkout.form
  if (savePaymentSource) {
    paymentSourceId = await createPaymentSource({ elements, stripe, api })
    return await pay({ paymentSourceId, api })
  }

  if (!paymentSourceId) {
    const paymentMethodId = await createPaymentMethod({ elements, stripe, api })
    return await pay({ paymentMethodId, api })
  }

  return await pay({ paymentSourceId, api })
})

const ADD_PAYEMNTCARD = gql`
  mutation AddPaymentCard($token: String!, $nickName: String!) {
    createPaymentSource(token: $token, nickname: $nickName) {
      id
    }
  }
`

const createPaymentSource = async ({ elements, stripe, api }) => {
  if (!elements || !stripe) {
    throw new Error('Stripe is not loaded')
  }

  const email = api.getState().checkout.form.email
  const cardElement = elements.getElement('cardNumber')
  const { error, token } = await stripe.createToken(cardElement, {
    email,
    currency: 'AUD',
  })

  if (error) {
    throw new Error(error.message)
  }

  const nickName = api.getState().checkout.form.paymentSourceNickname
  const { data } = await client.mutate({
    mutation: ADD_PAYEMNTCARD,
    variables: {
      token: token.id,
      nickName,
    },
  })

  return data.createPaymentSource.id
}

const createPaymentMethod = async ({ elements, stripe, api }) => {
  if (!elements || !stripe) {
    throw new Error('Stripe is not loaded')
  }

  const email = api.getState().checkout.form.email
  const cardElement = elements.getElement('cardNumber')
  const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: {
      email,
    },
  })
  if (paymentMethodError) throw new Error(error.message)

  return paymentMethod.id
}

const PAY_MUTATION = gql`
  ${CartFragment}

  mutation PayMutation(
    $gateway: ID!,
    $number: ID!,
    $email: String,
    $paymentMethodId: String,
    $paymentSource: ID,
    $shippingSameAsBilling: Boolean,
    $shippingAddress: AddressInput,
    $billingAddress: AddressInput,
  ) {
    pay(
      gateway: $gateway,
      number: $number,
      email: $email,
      paymentMethodId: $paymentMethodId,
      paymentSource: $paymentSource,
      shippingSameAsBilling: $shippingSameAsBilling,
      shippingAddress: $shippingAddress,
      billingAddress: $billingAddress,
    ) {
      redirect
      order {
        ...CartFragment
        reference
        itemSubtotal
        totalTax
        totalShippingCost
      }
    }
  }
`

const pay = async ({ paymentSourceId, paymentMethodId, api }) => {
  const form = api.getState().checkout.form

  const { data } = await client.mutate({
    mutation: PAY_MUTATION,
    variables: {
      number: form.number,
      gateway: form.gatewayId,
      email: form.email,
      paymentSource: paymentSourceId,
      shippingSameAsBilling: form.shippingSameAsBilling,
      shippingAddress: sanitiseAddress(form.shippingAddress),
      billingAddress: sanitiseAddress(form.billingAddress),
      paymentMethodId: paymentMethodId,
    },
  })

  return data.pay
}

const sanitiseAddress = (address) => {
  try {
    return addressSchema.validateSync(address, { stripUnknown: true })
  } catch (e) {
    //
  }

  return null // Invalid address
}

