import { createSlice } from '@reduxjs/toolkit'
import { navigate } from 'gatsby-link'
import { fire } from '../../../mini-beacon'
import { EVENT_COMMERCE_CHECKOUT_PROGRESS, EVENT_COMMERCE_PURCHASE } from '../../../mini-beacon/events'
import { formatError } from '../../../utils/error-formatter'
import { createOrUpdateAddress } from '../../thunks/account/create-or-update-address'
import { login } from '../../thunks/account/login'
import { register } from '../../thunks/account/register'
import { fetchCheckout } from '../../thunks/checkout/fetch-checkout'
import { guestCheckout } from '../../thunks/checkout/guest-checkout'
import {
  setBillingAddress, setBillingAndShippingAddress,
  setShippingAddress,
} from '../../thunks/checkout/shipping-addresses'
import { submit } from '../../thunks/checkout/submit'
import { setSubscription } from '../../thunks/checkout/subscription'
import { onFormChange, onFormInit } from './addresses'
import { logout } from './user'

const initialState = {
  error: undefined,
  loaded: false,
  loading: false,
  step: undefined,
  accountType: 'register',
  isSubscription: false,
  subscriptionFrequency: undefined,
  pending: {
    account: false,
    subscription: false,
    shipping: false,
    billing: false,
    payment: false,
  },
  form: {
    number: undefined,
    email: undefined,
    shippingAddressId: undefined,
    billingAddressId: undefined,
    shippingSameAsBilling: true,
    gatewayId: undefined,
    paymentSourceId: undefined,
    paymentSourceNickname: undefined,
    savePaymentSource: undefined,
    shippingAddress: {},
    billingAddress: {},
  },
  data: {
    checkout: undefined,
    me: undefined,
    gateways: undefined,
    cart: undefined,
    countries: undefined,
  },
}

export const { actions, reducer } = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = formatError(action.payload)
    },
    clearError(state) {
      state.error = undefined
    },
    setStep(state, action) {
      state.error = undefined
      state.step = action.payload

      fire(EVENT_COMMERCE_CHECKOUT_PROGRESS, { ...state })
    },
    stepForward(state) {
      state.error = undefined
      state.step++

      fire(EVENT_COMMERCE_CHECKOUT_PROGRESS, { ...state })
    },
    stepBack(state) {
      state.error = undefined
      state.step--

      fire(EVENT_COMMERCE_CHECKOUT_PROGRESS, { ...state })
    },
    setIsSubscription(state, action) {
      state.isSubscription = action.payload
    },
    setSubscriptionFrequency(state, action) {
      state.subscriptionFrequency = action.payload
    },
    setShippingSameAsBilling(state, action) {
      state.form.shippingSameAsBilling = action.payload
    },
    setAccountType(state, action) {
      state.accountType = action.payload

      fire(EVENT_COMMERCE_CHECKOUT_PROGRESS, { ...state })
    },
    setGateway(state, action) {
      state.form.gatewayId = Number(action.payload)
    },
    setPaymentSource(state, action) {
      state.form.paymentSourceId = Number(action.payload)
      state.form.savePaymentSource = false
      state.form.paymentSourceNickname = undefined
    },
    setNickname(state, action) {
      state.form.paymentSourceNickname = action.payload
    },
    savePaymentSource(state, action) {
      state.form.savePaymentSource = action.payload
    },
  },
  extraReducers: {
    ['paymentSources/changeMode']: (state, action) => {
      if (action.payload.mode === 'form') {
        state.form.paymentSourceId = undefined
      }
    },

    [setShippingAddress.pending]: (state, action) => {
      state.form.shippingAddressId = Number(action.meta.arg)
      state.pending.shipping = true
    },
    [setShippingAddress.fulfilled]: (state, action) => {
      state.pending.shipping = false
      state.form = { ...state.form, ...action.payload }
    },
    [setShippingAddress.rejected]: (state) => {
      state.pending.shipping = false
    },

    [setBillingAddress.pending]: (state, action) => {
      state.form.billingAddressId = Number(action.meta.arg)
      state.pending.billing = true
    },
    [setBillingAddress.fulfilled]: (state, action) => {
      state.pending.billing = false
      state.form = { ...state.form, ...action.payload }
    },
    [setBillingAddress.rejected]: (state) => {
      state.pending.billing = false
    },

    [setBillingAndShippingAddress.pending]: (state, action) => {
      state.form.shippingAddressId = Number(action.meta.arg)
      state.form.billingAddressId = Number(action.meta.arg)
      state.pending.shipping = true
      state.pending.billing = true
    },
    [setBillingAndShippingAddress.fulfilled]: (state, action) => {
      state.pending.shipping = false
      state.pending.billing = false
      state.form = { ...state.form, ...action.payload }
    },
    [setBillingAndShippingAddress.rejected]: (state) => {
      state.pending.shipping = false
      state.pending.billing = false
    },

    [fetchCheckout.pending]: (state) => {
      state.loading = true
      state.loaded = false
    },
    [fetchCheckout.fulfilled]: (state, action) => {
      state.loading = false
      state.loaded = true
      state.data = action.payload

      // The cart already has addresses assigned
      if (action.payload.checkout.shippingAddressId) {
        const { checkout } = action.payload
        state.form.billingAddressId = checkout.billingAddressId
        state.form.shippingAddressId = checkout.shippingAddressId
        state.form.shippingSameAsBilling = checkout.shippingSameAsBilling
      }

      const gateway = action.payload.gateways[0]
      if (gateway) {
        state.form.gatewayId = Number(gateway.id)
      }

      if (action.payload.checkout.customer) {
        state.form.email = action.payload.checkout.customer.email
      }

      state.form.number = action.payload.cart.number

      fire(EVENT_COMMERCE_CHECKOUT_PROGRESS, { ...state })
    },
    [fetchCheckout.rejected]: (state, action) => {
      state.loading = false
      state.loaded = false
      state.error = formatError(action.error)
    },

    [setSubscription.pending]: (state) => {
      state.pending.subscription = true
    },
    [setSubscription.fulfilled]: (state) => {
      state.pending.subscription = false
    },
    [setSubscription.rejected]: (state) => {
      state.pending.subscription = false
    },

    [createOrUpdateAddress.pending]: (state, action) => {
      if (action.meta.arg.isShippingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.shipping = true
      }

      if (action.meta.arg.isBillingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.billing = true
      }
    },
    [createOrUpdateAddress.fulfilled]: (state, action) => {
      if (action.meta.arg.isShippingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.shipping = false
        state.form.shippingAddress = {}
        state.form.shippingAddressId = Number(action.payload[action.payload.length - 1].id)
      }

      if (action.meta.arg.isBillingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.billing = false
        state.form.billingAddress = {}
        state.form.billingAddressId = Number(action.payload[action.payload.length - 1].id)
      }
    },
    [createOrUpdateAddress.rejected]: (state, action) => {
      if (action.meta.arg.isShippingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.shipping = false
      }

      if (action.meta.arg.isBillingAddress || action.meta.arg.isBillingAndShippingAddress) {
        state.pending.billing = false
      }
    },
    [submit.pending]: (state) => {
      state.error = undefined
      state.pending.payment = true
    },
    [submit.fulfilled]: (state, action) => {
      state.pending.payment = false

      for (let key in initialState) {
        state[key] = initialState[key]
      }

      let redirect = action.payload
      if (typeof redirect !== 'string') {
        redirect = action.payload.redirect
      }

      fire(EVENT_COMMERCE_PURCHASE, action.payload.order)

      navigate(`/${redirect}`)
    },
    [submit.rejected]: (state, action) => {
      state.pending.payment = false
      state.error = formatError(action.error)
    },
    [register.pending]: (state) => {
      state.error = undefined
      state.pending.account = true
    },
    [login.pending]: (state) => {
      state.error = undefined
      state.pending.account = true
    },
    [guestCheckout.pending]: (state) => {
      state.error = undefined
      state.pending.account = true
    },
    [register.rejected]: (state, action) => {
      state.error = formatError(action.error)
      state.pending.account = false
    },
    [login.rejected]: (state, action) => {
      state.error = formatError(action.error)
      state.pending.account = false
    },
    [guestCheckout.rejected]: (state, action) => {
      state.error = formatError(action.error)
      state.pending.account = false
    },
    [login.fulfilled]: (state, action) => {
      state.pending.account = false
      state.form.email = action.payload.email
      state.step = 2

      if (action.payload.customer.primaryShippingAddress?.id) {
        state.form.shippingAddressId = action.payload.customer.primaryShippingAddress.id
      }

      if (action.payload.customer.primaryBillingAddress?.id) {
        state.form.billingAddressId = action.payload.customer.primaryBillingAddress.id
      }
    },
    [register.fulfilled]: (state, action) => {
      state.pending.account = false
      state.form.email = action.payload.email
      state.step = 2
    },
    [guestCheckout.fulfilled]: (state, action) => {
      state.pending.account = false
      state.form.email = action.payload.customer.email
      state.data.checkout.customer = action.meta.arg
      state.form.shippingSameAsBilling = true
      state.step = 2
    },
    [onFormChange.type]: (state, action) => {
      const { name, value } = action.payload
      const [form, key] = name.split('.')
      state.form[`${form}Address`][key] = value
    },
    [onFormInit.type]: (state, action) => {
      const { values, prefix } = action.payload

      if (prefix) {
        for (let key in values) {
          state.form[`${prefix}Address`][key] = values[key]
        }
      }
    },
    [logout.type]: () => {
      if (/^\/checkout/.test(window.location.pathname)) {
        navigate('/cart')
      }
    },
  },
})

export const { setStep, stepBack, stepForward, setIsSubscription, setSubscriptionFrequency, setShippingSameAsBilling, setAccountType, setGateway, setPaymentSource, setPaymentToken, setNickname, setError, clearError, savePaymentSource } = actions

export const getError = (state) => state[name].error
