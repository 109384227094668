import { AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../../../../store/reducers/slices/addresses'
import { withNullErrorBoundary } from '../../../../utils'
import { motion } from '../../../motion'
import { Address } from './address'

const uuidString = (addresses) => addresses.join('.')

const AddressSelector = ({ type }) => {
  const pending = useSelector(state => state.checkout.pending[type])
  const addresses = useSelector(
    state => selectors.selectAll(state).filter(a => a.only === undefined || a.only === type).map(a => a.uuid),
    (a, b) => uuidString(a) === uuidString(b)
  )

  if (!addresses) {
    return null
  }

  return (
    <motion.div className={`loader loader--${pending ? 'loading' : 'loaded'}`} layout>
      <AnimateSharedLayout>
        <motion.div layout>
          <AnimatePresence initial={false}>
            {addresses.map((address) => (
              <Address key={address} address={address} type={type} />
            ))}
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
    </motion.div>
  )
}

export default withNullErrorBoundary(AddressSelector)
