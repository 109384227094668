import * as slices from './slices'
import { klaviyo } from './klaviyo'

const reducers = {
  klaviyo,
}

const reducer = {
  ...slices,
  ...reducers,
}

export default reducer
