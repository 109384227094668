export const EVENT_COMMERCE_PRODUCT_CLICK = 'commerceProductClick'
export const EVENT_COMMERCE_PRODUCT_IMPRESSION = 'commerceProductImpression'
export const EVENT_COMMERCE_PRODUCT_VIEW = 'commerceProductView'
export const EVENT_COMMERCE_CART_ADD = 'commerceCartAdd'
export const EVENT_COMMERCE_CART_REMOVE = 'commerceCartRemove'
export const EVENT_COMMERCE_PROMOTION_IMPRESSION = 'commercePromotionImpression'
export const EVENT_COMMERCE_PROMOTION_CLICK = 'commercePromotionClick'
export const EVENT_COMMERCE_CHECKOUT_START = 'commerceCheckoutStart'
export const EVENT_COMMERCE_CHECKOUT_PROGRESS = 'commerceCheckoutProgress'
export const EVENT_COMMERCE_PURCHASE = 'commercePurchase'
export const EVENT_COMMERCE_REFUND = 'commerceRefund'
