export const formatError = (error) => {
  const isProd = process.env.NODE_ENV === 'production'
  if (!isProd) {
    console.error(error)
  }
  const sentry = global.Sentry

  if (isProd && sentry) {
    //allows common errors to be ignored by Sentry
    let ignoreStrings = [
      'ResizeObserver loop limit exceeded',
      'Invalid username or password',
      'Your card was declined',
      'User already exists with email',
      'Coupon not found',
    ]

    let foundMatch = false
    for(let i = 0; i < ignoreStrings.length; i++) {
      if(error.message.indexOf(ignoreStrings[i]) !== -1) {
        foundMatch = true
      }
    }

    if(!foundMatch) {
      sentry.configureScope((scope) => {
        scope.setExtra('scope', typeof window !== 'undefined' ? 'browser' : 'server')
      })
      sentry.captureException(error)
    }
    else {
      console.log('null')
    }
  }

  if (typeof error !== 'string') {
    error = error.message
  }

  return error.replace(/(GraphQL error: )/gmi, '')
}
