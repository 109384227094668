export default [
  {
    name: 'whom',
    type: 'radio',
    title: 'Is this for you or someone else?',
    shouldShow: [],
    options: [
      {
        name: 'me',
        value: 'MYSELF',
        label: 'Me',
      },
      {
        name: 'someone',
        value: 'SOMEONE_ELSE',
        label: 'Someone else',
      },
    ],
  },
  {
    name: 'gender',
    type: 'radio',
    title: 'What is {selectorType} gender?',
    shouldShow: [],
    options: [
      {
        name: 'female',
        value: 'FEMALE',
        label: 'Female',
      },
      {
        name: 'male',
        value: 'MALE',
        label: 'Male',
      },
    ],
  },
  {
    name: 'type',
    type: 'radio',
    className: 'incontinence',
    title: 'What best describes the incontinence?',
    shouldShow: [],
    options: [
      {
        name: 'bladder',
        value: 'BLADDER',
        label: 'Bladder incontinence',
      },
      {
        name: 'bowel',
        value: 'BOWEL',
        label: 'Bowel incontinence',
      },
      {
        name: 'both',
        value: 'BOTH',
        label: 'Both bladder and bowel',
      },
    ],
  },
  {
    name: 'activity',
    type: 'radio',
    className: 'mobility',
    title: 'What best describes {selectorType} mobility?',
    shouldShow: [],
    options: [
      {
        name: 'active',
        value: 'ACTIVE',
        label: 'Active',
      },
      {
        name: 'assistance',
        value: 'REQUIRES_ASSISTANCE',
        label: 'Requires assistance',
      },
      {
        name: 'wheelchair',
        value: 'WHEELCHAIR_BOUND',
        label: 'Wheelchair bound',
      },
      {
        name: 'bedbound',
        value: 'BED_BOUND',
        label: 'Bedbound',
      },
    ],
  },
  {
    name: 'level',
    type: 'multi_radio',
    className: 'incontinence-day-night',
    title: 'Please describe the incontinence:',
    shouldShow: [{
      key: 'type',
      value: 'BLADDER',
      comparison: '==',
    }],
    groups: [
      {
        title: 'During the day:',
        suffix: 'day',
      },
      {
        title: 'During the night:',
        suffix: 'night',
      },
    ],
    options: [
      {
        name: 'no',
        value: null,
        label: 'None',
      },
      {
        name: 'small',
        value: 'SMALL',
        label: 'Small amount',
        subLabel: 'A few drops',
      },
      {
        name: 'medium',
        value: 'MEDIUM',
        label: 'Medium amount',
        subLabel: 'Up to 1/2 a cup',
      },
      {
        name: 'lot',
        value: 'LARGE',
        label: 'Large amount',
        subLabel: 'Up to one cup',
      },
      {
        name: 'unsure',
        value: 'XLARGE',
        label: 'More than a cup',
        subLabel: 'Sometimes a whole bladder',
      },
    ],
  },
  {
    name: 'size',
    type: 'select',
    title: 'What is {selectorType} {sizeType} size?',
    className: 'section-form',
    shouldShow: [],
    options: [
      {
        value: 'S',
        label: {
          MALE: 'Up to 30"',
          FEMALE: '4 - 12',
        },
      },
      {
        value: 'M',
        label: {
          MALE: '32" - 36"',
          FEMALE: '14 - 16',
        },
      },
      {
        value: 'L',
        label: {
          MALE: '38" - 44"',
          FEMALE: '18 - 20',
        },
      },
      {
        value: 'XL',
        label: {
          MALE: '46" - 56"',
          FEMALE: '22 - 24+',
        },
      },
    ],
  },
]
