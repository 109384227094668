import { advancedAnalyticsHandler } from './advancedAnalytics'
import { klaviyoHandler } from './klaviyo'

/**
 * Each handler should match:
 *
 * {
 *   format: (event, data, state) => data
 *   handle: (event, data) => void
 * }
 */
export const handlers = [
  klaviyoHandler,
  advancedAnalyticsHandler,
]
