import React, { useState, createContext } from 'react'
import { withNullErrorBoundary } from '../utils'

export const FormContext = createContext({
  state: {},
  setState: () => {},
})

const Form = ({ children, defaultState = {}, onSubmit, form = true, ...props }) => {
  if (form === false) {
    return (
      <div className={'form'}>
        {children}
      </div>
    )
  }

  const [state, setState] = useState(defaultState)
  const patchState = (newState) => setState({ ...state, ...newState })

  return (
    <form onSubmit={(e) => {
      if (typeof onSubmit === 'function') {
        onSubmit.call(this, e, state)
      }
    }} {...props}>
      <FormContext.Provider value={{ state, setState: patchState }}>
        {children}
      </FormContext.Provider>
    </form>
  )
}

export default withNullErrorBoundary(Form)
