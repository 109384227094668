import { motion } from '../../../../../motion'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeMode } from '../../../../../../store/reducers/slices/addresses'
import { withNullErrorBoundary } from '../../../../../../utils'

export const Button = ({ uuid, type, children }) => {
  const dispatch = useDispatch()
  const edit = () => dispatch(changeMode({ uuid, mode: 'form' }))

  return (
    <motion.div layout>
      {children({
        type: 'button',
        className: 'address-form-button',
        onClick: edit,
        children: (
          <>
            <i className="far fa-plus-circle"/> Or add new {type === 'billing' ? 'billing' : 'delivery'} address
          </>
        ),
      })}
    </motion.div>
  )
}

export default withNullErrorBoundary(Button)
