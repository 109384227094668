import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './src/store'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './src/apollo/client'
import { store } from './src/store'

export const wrapRootElement = ({ element })  => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <Provider store={store}>
          <ApolloProvider client={client}>
            {element}
          </ApolloProvider>
        </Provider>
      )}
    </PersistGate>
  )
}
