import React, { useContext } from 'react'
import FormLabel from './form-label'
import { FormContext } from '../form'
import { withNullErrorBoundary } from '../../utils'

const FormCheckboxOption = ({ checked, ...props }) => {
  const { state, setState } = useContext(FormContext)
  const isChecked = checked !== undefined ? checked : state[props.uniqueName] || false

  const setValue = () => {
    if (typeof props.onChange === 'function') {
      props.onChange(!isChecked)
    }

    setState({ [props.uniqueName]: !isChecked })
  }
  const id = `form-input-${props.uniqueName}`
  const name = props.uniqueName

  const label = typeof props.children === 'function' ? props.children({ id, name }) : (
    <FormLabel className={`${props.className}-label`} for={props.uniqueName} title={props.title || props.value} />
  )

  return (
    <div className={`form-check ${props.containerClass ? props.containerClass : ''}`}>
      <input checked={isChecked} onChange={() => setValue()} type="checkbox" name={name} value={props.value} id={id} className={`${props.className}-input`} />
      {label}
    </div>
  )
}

export default withNullErrorBoundary(FormCheckboxOption)
