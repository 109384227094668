export { reducer as checkout } from './checkout'
export { reducer as addresses } from './addresses'
export { reducer as paymentSources } from './paymentSources'
export { reducer as location } from './location'

import { persistReducer } from 'redux-persist'
import { reducer as cartReducer } from './cart'
import { reducer as userReducer } from './user'
import { reducer as productSelectorReducer } from './productSelector'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem(_key) {
      return Promise.resolve()
    },
  }
}

const storage =
  typeof window === 'undefined' ? createNoopStorage() : createWebStorage('local')

export const cart = persistReducer({
  key: 'cart',
  storage,
  blacklist: ['cartIsOpen', 'couponRejected', 'couponSuccessful'],
}, cartReducer)

export const user = persistReducer({
  key: 'user',
  storage,
  blacklist: ['alert'],
}, userReducer)

export const productSelector = persistReducer({
  key: 'product-selector',
  storage,
}, productSelectorReducer)
