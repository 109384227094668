import { selectors } from '../../store/reducers/slices/addresses'
import { motion } from '../motion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stepForward } from '../../store/reducers/slices/checkout'
import { withNullErrorBoundary } from '../../utils'
import AddressOptions from './step-2/address-options'
import ShippingAddress from './step-2/shipping-address'
import * as yup from 'yup'

class CheckoutStepTwo extends React.Component {
  render() {
    return (
      <div className="card-body delivery">
        <div className="row">
          <motion.div className="w-100">
            <motion.section layout className="w-100">
              <AddressOptions />
            </motion.section>
            {/*<OrderOptions />*/}
            <motion.section layout className="w-100">
              <ShippingAddress />
            </motion.section>
            <motion.div layout className="form-group col-12">
              <ContinueButton>
                continue to payment options
              </ContinueButton>
            </motion.div>
          </motion.div>
        </div>
      </div>
    )
  }
}

const ContinueButton = ({ children }) => {
  const isComplete = useSelector(stepIsComplete)
  const dispatch = useDispatch()
  const nextStep = () => dispatch(stepForward())

  return (
    <button
      onClick={nextStep}
      className="btn btn-outline-primary collapsed continue-to-payment"
      type="button"
      data-toggle="collapse"
      data-target="#collapseThree"
      aria-expanded="true"
      aria-controls="collapseOne"
      disabled={!isComplete}
    >
      {children}
    </button>
  )
}

export const addressSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  locality: yup.string().required(),
  countryCode: yup.string().required(),
  postalCode: yup.string().min(4).max(4),
  administrativeArea: yup.string().when('countryCode', {
    is: 'AU',
    then: yup.string().required(),
  }),
  title: yup.string().when('saveAddress', {
    is: true,
    then: yup.string().required(),
  }),
  saveAddress: yup.boolean(),
})

const stepIsComplete = (state) => {
  const checkout = state.checkout
  const addresses = selectors.selectAll(state)
  const addressFormOpen = addresses.some(addr => addr.mode === 'form')
  const hasAddresses = addresses.filter(addr => addr.id).length > 0

  if (addressFormOpen && hasAddresses) {
    return false
  }

  if (checkout.form.shippingAddressId) {
    if (checkout.form.billingAddressId || checkout.form.shippingSameAsBilling) {
      return true
    }
  }

  if (addressSchema.isValidSync(checkout.form.shippingAddress)) {
    if (!checkout.form.shippingSameAsBilling) {
      return addressSchema.isValidSync(checkout.form.billingAddress)
    }

    return true
  }

  return false
}

export default withNullErrorBoundary(CheckoutStepTwo)


