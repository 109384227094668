import { gql } from 'apollo-boost'

const AddressFragment = gql`
  fragment address on AddressInterface {
    id
    title
    fullName
    firstName
    lastName
    addressLine1
    addressLine2
    locality
    postalCode
    countryCode
    administrativeArea
  }
`

export default AddressFragment
