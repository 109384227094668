import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { login } from '../../thunks/account/login'
import { register } from '../../thunks/account/register'
import { fetchCheckout } from '../../thunks/checkout/fetch-checkout'
import { guestCheckout } from '../../thunks/checkout/guest-checkout'

const adapter = createEntityAdapter({
  selectId: model => model.uuid,
})

const newCard = ({ mode = 'button' } = {}) => ({
  mode,
  uuid: uuid(),
})

export const { reducer, actions, name } = createSlice({
  name: 'paymentSources',
  initialState: adapter.getInitialState({
    newUser: false,
  }),
  reducers: {
    changeMode(state, action) {
      const { uuid, mode } = action.payload

      adapter.updateOne(state, {
        id: uuid,
        changes: {
          mode,
        },
      })
    },
  },
  extraReducers: {
    ['checkout/setPaymentSource']: (state) => {
      const ids = adapter.getSelectors().selectIds(state)

      adapter.updateOne(state, {
        id: ids[ids.length - 1],
        changes: {
          mode: 'button',
        },
      })
    },
    [fetchCheckout.fulfilled]: (state, action) => {
      setupSources(state, action.payload.paymentSources)
    },
    [login.fulfilled]: (state, action) => {
      setupSources(state, action.payload.customer?.paymentSources)
    },
    [register.fulfilled]: (state, action) => {
      setupSources(state, action.payload.customer?.paymentSources)
    },
    [guestCheckout.fulfilled]: (state, action) => {
      setupSources(state, action.payload.customer?.paymentSources)
    },
  },
})

const setupSources = (state, sources) => {
  const buttonOrCard = newCard()

  if (sources) {
    if (sources.length === 0) {
      buttonOrCard.mode = 'form'
      state.newUser = true
    } else {
      state.newUser = false
    }

    adapter.setAll(state, sources.map(s => ({ mode: 'card', uuid: uuid(), ...s })).concat([buttonOrCard]))
  } else {
    adapter.setAll(state, [newCard({ mode: 'form' })])
    state.newUser = true
  }
}

export const selectors = adapter.getSelectors(state => state[name])

export const { changeMode } = actions
