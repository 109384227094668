import { createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../../apollo/client'
import CartFragment from '../../../apollo/fragments/cart-fragment'
import { gql } from 'apollo-boost'

const ADD_COUPON_TO_CART = gql`
  ${CartFragment}

  mutation AddCouponToCart($number: ID!, $coupon: String!) {
    addCouponToCart(number: $number, coupon: $coupon) {
      ... CartFragment
    }
  }
`

export const addCouponToCart = createAsyncThunk('cart/addCouponToCart', async (coupon, api) => {
  const number = api.getState().cart.number

  const { data } = await client.mutate({
    mutation: ADD_COUPON_TO_CART,
    variables: { number, coupon },
  })

  return data.addCouponToCart
})
