import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import CartFragment from '../../../apollo/fragments/cart-fragment'
import { setSubscriptionFrequency } from '../../reducers/slices/checkout'

export const setSubscription = createAsyncThunk('cart/setSubscription', async (schedule, api) => {
  const number = api.getState().cart.number

  if (number === undefined) {
    throw new Error('Cart is empty')
  }

  api.dispatch(setSubscriptionFrequency(schedule))
  const { data } = await client.mutate({
    mutation: UPDATE_SCHEDULE_MUTATION,
    variables: { number, schedule },
  })

  return data.updateCart
})

const UPDATE_SCHEDULE_MUTATION = gql`
  ${CartFragment}

  mutation UpdateShedule($number: ID!, $schedule: QueryArgument!) {
    updateCart(number: $number, schedule: $schedule) {
      ...CartFragment
    }
  }
`

