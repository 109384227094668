import { motion } from '../motion'
import React, { useContext } from 'react'
import { FormContext } from '../form'
import { withNullErrorBoundary } from '../../utils'

const FormCheckoutRadioOption = ({ groupName, uniqueName, checked, className, children, value, uniqueLabelClassName, disabled, title, onChange, ...props }) => {
  const { state, setState } = useContext(FormContext)
  const isChecked = checked !== undefined ? checked : state[groupName] === value
  const setValue = () => {
    if (typeof onChange === 'function') {
      onChange(value)
    }

    setState({ [groupName]: value })
  }

  const classes = ['form-check']
  if (disabled) {
    classes.push('form-check--disabled')
  }

  return (
    <motion.div layout className={classes.join(' ')} title={title} { ...props }>
      <input checked={isChecked} onChange={() => setValue()} type="radio" name={groupName} {...props} id={`form-input-${uniqueName}`} className={`${className}-input`} />
      <label htmlFor={`form-input-${uniqueName}`} className={`${className}-label ${uniqueLabelClassName || ''}`}>
        {children}
      </label>
    </motion.div>
  )
}

export default withNullErrorBoundary(FormCheckoutRadioOption)
