import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import { UserFragment } from '../../../apollo/fragments/user-fragment'

const LOGIN_MUTATION = gql`
  ${UserFragment}

  mutation Login($loginName: String!, $password: String!, $number: ID) {
    login(loginName: $loginName, password: $password, number: $number) {
      ...UserFragment
    }
  }
`

export const login = createAsyncThunk('account/login', async ({ loginName, email, password }, api) => {
  const number = api.getState().cart.number

  const { data } = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      loginName: loginName || email,
      password,
      number,
    },
  })

  return data.login
})
