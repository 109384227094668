import { createSlice } from '@reduxjs/toolkit'
import { fire } from '../../../mini-beacon'
import { EVENT_COMMERCE_CART_REMOVE } from '../../../mini-beacon/events'
import { store } from '../../index'
import { removeItem } from '../../thunks/cart/remove-item'
import { fetchCheckout } from '../../thunks/checkout/fetch-checkout'
import { addCouponToCart } from '../../thunks/cart/add-coupon-to-cart'
import { submit } from '../../thunks/checkout/submit'
import { navigate } from './location'
import { logout } from './user'

const initialState = {
  number: undefined,
  cartIsOpen: false,
  cartIsClosing: false,
  isPending: false,
  noOfItems: 0,
  items: [],
  cartTotal: 0,
  totalDiscount: 0,
  totalTax: 0,
  totalTaxIncluded: 0,
  totalShippingCost: 0,
  couponRejected: false,
  couponSuccessful: false,
}

const populate = (oldState, newState) => {
  for (let key in newState) {
    oldState[key] = newState[key]
  }

  if (oldState.items.length === 0 && newState.items) {
    oldState.number = undefined
  }
}

const resetState = (state) => {
  populate(state, initialState)
}

export const { actions, reducer } = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: (state) => {
      resetState(state)
    },
    setCart: (state, action) => {
      if (action.payload.items && action.payload.items.length === 0) {
        resetState(state)
      } else {
        populate(state, action.payload)
      }
    },
    openCart: (state, action) => {
      state.cartIsOpen = true
      if (typeof action.payload === 'boolean') {
        state.cartIsClosing = action.payload

        if (state.cartIsClosing) {
          setTimeout(() => {
            store.dispatch(closeCart(true))
          }, 3000)
        }
      }
    },
    keepCartOpen: (state) => {
      state.cartIsClosing = false
    },
    closeCart: (state, action) => {
      if (typeof action.payload === 'boolean' && action.payload) {
        if (state.cartIsClosing) {
          state.cartIsOpen = false
        }
      } else {
        state.cartIsOpen = false
      }
    },
    setPending: (state, action) => {
      state.isPending = action.payload
    },
    resetCouponReject: (state, action) => {
      state.couponRejected = action.payload
    },
  },
  extraReducers: {
    [fetchCheckout.fulfilled]: (state, action) => {
      populate(state, action.payload.cart)
    },
    [submit.fulfilled]: resetState,
    [navigate.type]: (state) => {
      state.cartIsOpen = false
      state.couponRejected = false
      state.couponSuccessful = false
    },
    [removeItem.pending]: (state) => {
      state.isPending = true
    },
    [removeItem.rejected]: (state) => {
      state.isPending = false
    },
    [removeItem.fulfilled]: (state, action) => {
      fire(EVENT_COMMERCE_CART_REMOVE, state.items.find((item) => item.id === action.meta.arg))

      state.isPending = false
      populate(state, action.payload)
    },
    [addCouponToCart.pending]: (state) => {
      state.isPending = true
    },
    [addCouponToCart.rejected]: (state) => {
      state.isPending = false
      state.couponRejected = true
      state.couponSuccessful = false
    },
    [addCouponToCart.fulfilled]: (state, action) => {
      state.isPending = false
      state.couponRejected = false
      state.couponSuccessful = true
      populate(state, action.payload)
    },
    [logout.type]: resetState,
  },
})

export const { resetCart, setCart, openCart, closeCart, setPending, keepCartOpen, resetCouponReject } = actions
