/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { wrapRootElement } from './root'

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.pathname === '/product/product-select/') {
    return false
  }

  return true
}
