import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import { UserFragment } from '../../../apollo/fragments/user-fragment'

const REGISTER = gql`
  ${UserFragment}

  mutation RegisterMutation($email: String, $firstName: String, $lastName: String, $password: String, $acceptsMarketing: Boolean, $number: ID) {
    register(email: $email, firstName: $firstName, lastName: $lastName, newPassword: $password, acceptsMarketing: $acceptsMarketing, loginOnSuccess: true, number: $number) {
      ...UserFragment
    }
  }
`

export const register = createAsyncThunk('account/register', async (user, api) => {
  const number = api.getState().cart.number

  const { data } = await client.mutate({
    mutation: REGISTER,
    variables: {
      number,
      ...user,
    },
  })

  return data.register
})
