import { createSlice } from '@reduxjs/toolkit'
import { defaultValue } from '../../../components/layout/withUser'
import { formatError } from '../../../utils/error-formatter'
import { login } from '../../thunks/account/login'
import { register } from '../../thunks/account/register'
import { navigate } from './location'

export const { reducer, actions } = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    alert: undefined,
    username: undefined,
    isLogged: false,
    firstName: undefined,
    fullName: undefined,
    email: undefined,
    phoneNumber: undefined,
  },
  reducers: {
    setUser(state, action) {
      for (let key in action.payload) {
        state[key] = action.payload[key]
      }
    },
    logout: () => defaultValue,
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true
      state.alert = undefined
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false
      state.alert = formatError(action.error)
    },
    [login.fulfilled]: (state, action) => {
      state.isLoading = false
      state.alert = undefined

      for (let key in action.payload) {
        state[key] = action.payload[key]
      }

      state.isLogged = true
    },

    [register.pending]: (state) => {
      state.isLoading = true
      state.alert = undefined
    },
    [register.rejected]: (state, action) => {
      state.isLoading = false
      state.alert = formatError(action.error)
    },
    [register.fulfilled]: (state, action) => {
      state.isLoading = false
      state.alert = undefined

      for (let key in action.payload) {
        state[key] = action.payload[key]
      }

      state.isLogged = true
    },
    [navigate.type]: (state) => {
      state.alert = undefined
    },
  },
})

export const { setUser, logout } = actions
