import React from 'react'
import { withNullErrorBoundary } from '../../utils'

class FormLabel extends React.Component {
  render() {
    const htmlFor = this.props.htmlFor || `form-input-${this.props.for}`

    return (
      <>
        <label htmlFor={htmlFor} className={this.props.className}>
          {this.props.children}
          {this.props.title}
        </label>
      </>
    )
  }
}

export default withNullErrorBoundary(FormLabel)
