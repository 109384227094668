import { createSlice } from '@reduxjs/toolkit'
import { globalHistory } from '@reach/router'
import { dispatch } from '../../index'

globalHistory.listen(({ location }) => dispatch(navigate({ ...location })))

export const { actions, reducer } = createSlice({
  name: 'location',
  initialState: { ...globalHistory.location },
  reducers: {
    navigate: (state, action) => action.payload,
  },
})

export const { navigate } = actions
