import { createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../../apollo/client'
import CartFragment from '../../../apollo/fragments/cart-fragment'
import { PaymentSourcesFragment } from '../../../apollo/fragments/payment-sources-fragment'
import AddressFragment from '../../../components/user-accounts/get-addresses'
import { gql } from 'apollo-boost'
import { setShippingSameAsBilling } from '../../reducers/slices/checkout'
import { setBillingAddress, setShippingAddress } from './shipping-addresses'

export const fetchCheckout = createAsyncThunk('checkout/fetchCheckout', async (_, api) => {
  const number = api.getState().cart.number

  if (number === undefined) {
    throw new Error('Cart is empty')
  }

  const { data } = await client.query({
    query: CHECKOUT_QUERY,
    fetchPolicy: 'no-cache',
    variables: { number },
  })

  const { checkout, me } = data
  if (
    me
    && !(checkout.shippingAddressId || checkout.billingAddressId)
    && (me.customer.primaryShippingAddress?.id || me.customer.primaryBillingAddress?.id)
  ) {
    const shipping = me.customer.primaryShippingAddress.id
    const billing = me.customer.primaryBillingAddress.id

    if (shipping) {
      api.dispatch(setShippingAddress(shipping))
    }

    if (billing) {
      api.dispatch(setBillingAddress(billing))

      if (billing === shipping) {
        api.dispatch(setShippingSameAsBilling(true))
      }
    } else {
      api.dispatch(setShippingSameAsBilling(true))
    }
  }

  return data
})

const CHECKOUT_QUERY = gql`
  ${CartFragment}
  ${AddressFragment}
  ${PaymentSourcesFragment}

  query GetCheckoutQuery($number: ID!) {
    cart(number: $number) {
      ...CartFragment
    }
    gateways {
      id
      handle
      name
    }
    countries {
      iso
      name
      states {
        abbreviation
        name
      }
    }
    checkout: cart(number: $number) {
      billingAddressId
      shippingAddressId
      shippingSameAsBilling
      shippingMethodHandle
      shippingAddress {
        ...address
      }
      billingAddress {
        ...address
      }
      customer {
        firstName
        lastName
        email
      }
      availableShippingMethods {
        handle
        name
        id
        priceForOrder(order: $number)
        shippingRules(matching: $number) {
          description
          id
          name
        }
      }
    }
    me {
      customer {
        primaryShippingAddress {
          id
        }
        primaryBillingAddress {
          id
        }
        addresses {
          ...address
        }
      }
    }
    paymentSources {
      ...PaymentSourcesFragment
    }
  }
`

