import { createReducer } from '@reduxjs/toolkit'
import { isBrowser } from '../../utils/is-ssr'
import { login } from '../thunks/account/login'
import { register } from '../thunks/account/register'
import { guestCheckout } from '../thunks/checkout/guest-checkout'

export const getKlaviyo = () => (isBrowser() && window._learnq) || []

const identify = ({ email, firstName, lastName }) => {
  const identity = {
    '$email': email,
    '$firstName': firstName,
    '$lastName': lastName,
  }

  for (let key in identity) {
    if (!identity[key]) {
      delete identity[key]
    }
  }

  getKlaviyo().push(['identify', identity])

  return null
}

export const klaviyo = createReducer(null, {
  [login.fulfilled]: (state, action) => {
    return identify(action.payload)
  },
  [register.fulfilled]: (state, action) => {
    return identify(action.payload)
  },
  [guestCheckout.fulfilled]: (state, action) => {
    return identify(action.meta.arg)
  },
})
