import { createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../../apollo/client'
import CartFragment from '../../../apollo/fragments/cart-fragment'
import { gql } from 'apollo-boost'

const REMOVE_FROM_CART = gql`
  ${CartFragment}

  mutation RemoveFromCart($number: ID!, $id: ID!) {
    updateLineItemsInCart(number: $number, lineItems: [
      {
        id: $id,
        remove: true
      }
    ]) {
      ... CartFragment
    }
  }
`


export const removeItem = createAsyncThunk('cart/removeItem', async (id, api) => {
  const number = api.getState().cart.number

  const { data } = await client.mutate({
    mutation: REMOVE_FROM_CART,
    variables: { id, number },
  })

  return data.updateLineItemsInCart
})
