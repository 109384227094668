import {
  createDomMotionComponent,
} from 'framer-motion'

export const motion = [ // Add more as needed
  'button',
  'div',
  'footer',
  'form',
  'label',
  'section',
].reduce((m, tag) => ({
  ...m,
  [tag]: createDomMotionComponent(tag),
}), {})
