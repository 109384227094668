import { Field, Form, Formik, useFormikContext } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { motion } from '../motion'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onFormChange, onFormInit } from '../../store/reducers/slices/addresses'
import FormLabel from '../form/form-label'

export const FormAlertContext = createContext({
  alert: null,
  setAlert: (_alert) => {},
})

export const AddressForm = ({ onSubmit, children, exclude, prefix, uuid, initialValues = {}, ...props }) => {
  const [alert, setAlert] = useState(null)
  const countries = useSelector(state => state.checkout.data.countries)
  const isNew = useSelector(state => state.addresses.newUser)
  const prefixName = useMemo(() => (name = '') => prefix ? `${prefix}.${name}` : name, [prefix])
  const dispatch = useDispatch()
  const onChange = useMemo(() => (e) => dispatch(onFormChange({
    uuid,
    name: e.target.name,
    value: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
  })), [])

  const defaultValues = useMemo(() => {
    const initialValuesMinusNull = Object.fromEntries(Object.entries(initialValues).filter(([_, v]) => v != null))
    let returnValue = {}
    const defaults = {
      firstName: '',
      lastName: '',
      title: '',
      countryCode: countries[0].iso,
      addressLine1: '',
      addressLine2: '',
      locality: '',
      administrativeArea: '',
      saveAddress: !isNew,
      postalCode: '',
    }

    if (prefix) {
      returnValue[prefix] = {
        ...defaults,
        ...initialValuesMinusNull,
      }
    } else {
      returnValue = {
        ...defaults,
        ...initialValuesMinusNull,
      }
    }

    return returnValue
  }, [prefix, initialValues])

  useEffect(() => {
    dispatch(onFormInit({
      prefix,
      uuid,
      values: prefix ? defaultValues[prefix] : defaultValues,
    }))
  }, [])

  const inputs = [
    {
      name: 'firstName',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="First Name" className="required" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'lastName',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="Last Name" className="required" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'countryCode',
      render: ({ name }) => {
        const { setFieldValue } = useFormikContext()

        return (
          <>
            <FormLabel for={name} title="Country" className="required" />
            <Field onChange={(e) => {
              setFieldValue(name, e.target.value)
              onChange(e)
            }} as="select" id={`form-input-${name}`} name={name} className="form-control">
              {countries.map((country) => (
                <option key={country.iso} value={country.iso}>{country.name}</option>
              ))}
            </Field>
          </>
        )
      },
    },
    {
      name: 'addressLine1',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="Address Line 1" className="required" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'addressLine2',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="Address Line 2" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'locality',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="Suburb" className="required" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'administrativeArea',
      conditional: () => true,
      render: ({ name }) => {
        const { values, setFieldValue } = useFormikContext()
        const country = countries.find(c => c.iso === (prefix ? values[prefix].countryCode : values.countryCode))
        return country?.states.length > 0 ? (
          <>
            <FormLabel for={name} title="State" className="required" />
            <Field onChange={(e) => {
              setFieldValue(name, e.target.value)
              onChange(e)
            }} as="select" id={`form-input-${name}`} name={name} className="form-control" type="text">
              <option value="">Please select</option>
              {country.states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </Field>
          </>
        ) : null
      },
    },
    {
      name: 'postalCode',
      render: ({ name }) => (
        <>
          <FormLabel for={name} title="Postcode" className="required" />
          <Field onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
        </>
      ),
    },
    {
      name: 'saveAddress',
      conditional: () => isNew,
      render: ({ name }) => {
        const { values, setFieldValue } = useFormikContext()
        const checked = prefix ? values[prefix].saveAddress : values.saveAddress

        return (
          <>
            <div className="form-check billing-address-wrp">
              <Field type="checkbox" onChange={(e) => {
                setFieldValue(name, e.target.checked)
                onChange(e)
              }} name={name} id={`form-input-${name}`} className="form-check-input" />
              <FormLabel for={name} title="Save address for future purchases" className={`form-check-label confirm pt-0 pb-0 save-address ${checked ? 'checked' : ''}`} />
            </div>
          </>
        )
      },
    },
    {
      name: 'title',
      render: ({ name }) => {
        const { values } = useFormikContext()
        const saveAddress = prefix ? values[prefix].saveAddress : values.saveAddress

        return (
          <AnimatePresence initial={false}>
            {
              saveAddress ? (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 25,
                  }}
                >
                  <div className="nickname-field">
                    <FormLabel for={name} title="Address Nickname" className="required" />
                    <Field placeholder="eg. Home" onInput={onChange} name={name} id={`form-input-${name}`} className="form-control" type="text" />
                  </div>
                </motion.div>
              ) : null
            }
          </AnimatePresence>
        )
      },
    },
  ].filter(({ name, conditional }) => {
    if (exclude.indexOf(name) !== -1) {
      return false
    }

    return typeof conditional === 'function' ? conditional() : true
  }).map(({ name, render: Component }) => (
    <Component name={prefixName(name)} key={name} />
  ))

  return (
    <FormAlertContext.Provider value={{ alert, setAlert }}>
      <Formik initialValues={defaultValues} onSubmit={onSubmit}>
        <Form {...props}>
          {alert && (
            <div className={['alert', alert.success ? 'alert-danger success-message' : 'alert-danger'].join(' ')}>
              {alert.success && (
                <i className="fal fa-thumbs-up"/>
              )}
              {alert.message}
            </div>
          )}

          {inputs}

          {children}
        </Form>
      </Formik>
    </FormAlertContext.Provider>
  )
}

AddressForm.defaultProps = {
  exclude: [],
  prefix: '',
}

