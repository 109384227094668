import { AnimatePresence } from 'framer-motion'
import { motion } from '../../../../../motion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeMode, selectors } from '../../../../../../store/reducers/slices/addresses'
import { setBillingAddress, setShippingAddress } from '../../../../../../store/thunks/checkout/shipping-addresses'
import { withNullErrorBoundary } from '../../../../../../utils'
import FormCheckoutRadioOption from '../../../../../form/form-checkout-radio-option'

export const Card = ({ uuid, type, children }) => {
  const address = useSelector(state => selectors.selectById(state, uuid))
  const dispatch = useDispatch()
  const edit = () => dispatch(changeMode({ uuid, mode: 'form' }))
  const { newtitle: title, body, phone } = useAddress(address)

  const setSelectedMethod = () => dispatch(type === 'shipping' ? setShippingAddress(uuid) : setBillingAddress(uuid))
  const isSelected = useSelector(state => {
    const cartAddress = state.cart[`${type}Address`]
    return (
      cartAddress
      && cartAddress.addressLine1 == address.addressLine1 
      && cartAddress.addressLine2 == address.addressLine2
      && cartAddress.fullName == address.fullName
      && cartAddress.countryCode == address.countryCode
      && cartAddress.locality == address.locality
      && cartAddress.administrativeArea == address.administrativeArea
      && cartAddress.postalCode == address.postalCode
    )}
  )
  const shippingSameAsBilling = useSelector(state => state.cart.billingSameAsShipping)
  const showFooter = isSelected && (!shippingSameAsBilling || type !== 'billing')

  return (
    <>
      <motion.div
        layout
        style={{ display: 'flex', alignItems: 'stretch' }}
        transition={{ delay: 0.2 }}
        variants={{
          show: { opacity: 1 },
          hide: { opacity: 0 },
        }}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <FormCheckoutRadioOption
          style={{ width: '100%' }}
          onChange={setSelectedMethod}
          checked={isSelected}
          className={'form-check'}
          uniqueName={`checkout-${type}-address-${uuid}`}
          groupName={`${type}-address`}
        >
          <motion.div layout>
            <AnimatePresence>
              <motion.div
                key={'body'}
                className="label-data"
              >
                {title}
                <span className="address">{body}</span>
              </motion.div>
              {phone && (
                <motion.div
                  key={'phone'}
                  transition={{ delay: 0.2 }}
                  variants={{
                    show: { opacity: 1 },
                    hide: { opacity: 0 },
                  }}
                  initial="hide"
                  animate="show"
                  exit="hide"
                  className="contact"
                >
                  <span><i className="far fa-phone"/>{phone}</span>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </FormCheckoutRadioOption>
      </motion.div>
      <AnimatePresence initial={false}>
        {showFooter && (
          <motion.footer
            key={'edit'}
            variants={{
              hide: { opacity: 0, height: 0 },
              show: { opacity: 1, height: 'auto' },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 25,
            }}
          >
            <div className="address-card__footer">
              {children({
                type: 'button',
                onClick: edit,
                children: 'Edit',
              })}
            </div>
          </motion.footer>
        )}
      </AnimatePresence>
    </>
  )
}

export default withNullErrorBoundary(Card)

const useAddress = (address) => {
  const { title, fullName, firstName, lastName, phone } = address
  const name = fullName ? fullName : `${firstName} ${lastName}`
  const newTitle = title ? `${title} - ${name}` : name
  const newAddress = { ...address }
  const country = useSelector(state => state.checkout.data.countries.find(c => c.iso === address.countryCode))

  if (!address.id) {
    if (country) {
      newAddress.country = country.name
    }

    if (address.administrativeArea && country) {
      newAddress.state = country.states.find(s => s.abbreviation === address.administrativeArea).name
    }
  }

  return { newTitle, phone, body: buildText(newAddress) }
}

const buildText = (address) => [
  address.addressLine1,
  address.addressLine2,
  address.locality,
  address.postalCode,
  address.administrativeArea,
  address.countryCode,
].filter(s => Boolean(s)).join(', ')
