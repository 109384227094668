import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { formatError } from '../../../utils/error-formatter'
import { getSuggestions } from '../../thunks/product-selector/get-suggestions'
import rowData from '../../../components/product-selector/data/rows'
import { v4 as uuid } from 'uuid'

const rows = createEntityAdapter()
const suggestions = createEntityAdapter()

const initialRows = rowData.map(({ options, ...row }) => ({
  ...row,
  id: uuid(),
  isComplete: false,
  options: options.map(option => ({
    ...option,
  })),
}))

const emptyState = rows.getInitialState({
  activeRow: initialRows[0].id,
  visibleRows: [initialRows[0].id],
  activeRowIndex: 0,
  isComplete: false,
  form: {},
  suggestions: suggestions.getInitialState({
    isLoading: false,
    isLoaded: false,
    error: undefined,
  }),
})

const isComplete = (state, { id }) => {
  const row = rows.getSelectors().selectById(state, id)

  if (row.groups) {
    for (let group of row.groups) {
      if (state.form[`${row.name}_${group.suffix}`] === undefined) {
        return false
      }
    }

    return true
  } else if (row.type === 'select') {
    return Boolean(state.form[row.name])
  } else {
    return state.form[row.name] !== undefined
  }
}

const initialState = rows.upsertMany(emptyState, initialRows)

const shouldShowComparitor = (row, state) => row.shouldShow.reduce((carry, { key, value, comparison }) => {
  switch (comparison) { // These are inverses, as we're checking false values
  case '!=':
    if (state.form[key] === value) {
      carry = false
    }
    break
  case '==':
    if (state.form[key] !== value) {
      carry = false
    }
    break
  }

  return carry
}, true)

export const { reducer, actions } = createSlice({
  name: 'productSelector',
  initialState,
  reducers: {
    setRow: (state, action) => {
      const { id, name, value } = action.payload

      // Update the form (what we submit)
      state.form[name] = value
      const isNowComplete = isComplete(state, action.payload)
      const wasComplete = rows.getSelectors().selectById(state, id).isComplete

      // Update the row
      rows.updateOne(state, {
        id,
        changes: {
          isComplete: isNowComplete,
        },
      })

      // Simple move to next row
      if (!wasComplete && isNowComplete) {
        const rowIds = rows.getSelectors().selectIds(state)
        const currentRowIndex = rowIds.findIndex((rowId) => rowId === id)

        if (state.activeRowIndex <= currentRowIndex) {
          state.activeRowIndex = currentRowIndex + 1
        }
      }

      // Show/hide columns if they want to be shown
      // Additionally, we might need to further increment the active row index
      let i = 0
      const allRows = rows.getSelectors().selectAll(state)
      do {
        const row = allRows[i]
        const shouldShow = shouldShowComparitor(row, state)
        const rowIsVisible = state.visibleRows.includes(row.id)

        if (shouldShow && !rowIsVisible) {
          state.visibleRows.push(row.id)
        } else if (!shouldShow && rowIsVisible) {
          state.visibleRows = state.visibleRows.filter(id => id !== row.id)
        }

        if (!shouldShow && i === state.activeRowIndex) { // This is the last row and we aren't showing it
          state.activeRowIndex++ // Check the next row too
        }
      } while (i++ < Math.min(state.activeRowIndex, allRows.length - 1))

      // Define the active row ID
      state.activeRow = rows.getSelectors().selectIds(state)[state.activeRowIndex]

      // If all rows are done, update the general state
      state.isComplete = rows.getSelectors().selectAll(state).every(row => {
        if (shouldShowComparitor(row, state)) {
          return row.isComplete
        }

        return true // Ignore row
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getSuggestions.pending]: (state) => {
      state.suggestions.isLoaded = false
      state.suggestions.isLoading = true
      state.suggestions.error = undefined
    },
    [getSuggestions.rejected]: (state, action) => {
      state.suggestions.isLoaded = false
      state.suggestions.isLoading = false
      state.suggestions.error = formatError(action.error)
    },
    [getSuggestions.fulfilled]: (state, action) => {
      state.suggestions.isLoaded = true
      state.suggestions.isLoading = false

      suggestions.setAll(state.suggestions, action.payload)
    },
  },
})

export const rowSelectors = rows.getSelectors(state => state.productSelector)
export const suggestionSelectors = suggestions.getSelectors(state => state.productSelector.suggestions)

export const { setRow, reset } = actions

