import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { initMessageListener } from 'redux-state-sync/dist/syncState'
import { isBrowser, isSsr } from '../utils/is-ssr'
import { reset } from './actions/reset'
import { syncMiddleware } from './middleware/sync'
import { token } from './middleware/token'
import reducer from './reducers'
import { persistStore } from 'redux-persist'
import { setCart } from './reducers/slices/cart'
import { fetchCheckout } from './thunks/checkout/fetch-checkout'

const rootReducer = (state, action) => {
  if (action.type === reset.type) {
    state = {
      ...state,
      addresses: undefined,
      paymentSources: undefined,
      checkout: undefined,
    }
  }

  return combineReducers(reducer)(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }).concat(isSsr() ? [] : [token, syncMiddleware]),
})

initMessageListener(store)

export default _ => store

export const persistor = persistStore(store)

export const { dispatch, getState, subscribe } = store

if (process.env.NODE_ENV === 'development' && isBrowser()) {
  if (typeof window.__DEBUG__ === 'undefined') {
    window.__DEBUG__ = {}
  }

  window.__DEBUG__.setCart = (number) => {
    dispatch(setCart({ number }))
    dispatch(fetchCheckout())
  }
}
