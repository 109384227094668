import { storage } from '../../utils'

export const token = store => next => action => {
  const response = next(action)
  const token = store.getState()?.user?.token

  if (token !== undefined) {
    storage.setItem('token', token)
  } else {
    storage.removeItem('token')
  }

  return response
}
