import { motion } from '../../../../motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../../../../../store/reducers/slices/addresses'

export const BaseWrapper = ({ uuid, type, children }) => {
  const address = useSelector(state => selectors.selectById(state, uuid))
  const selectedMethod = useSelector(state => state.checkout.form[`${type}AddressId`])
  const isActive = Number(selectedMethod) === Number(address.id)
  const isNew = useSelector(state => state.addresses.newUser)

  return (
    <motion.div
      className={`address-wrapper address-wrapper--${isActive ? 'active' : 'default'} address-wrapper--${!isNew ? 'logged-in' : 'logged-out'}`}
    >
      {children}
    </motion.div>
  )
}
