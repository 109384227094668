import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../../../../../store/reducers/slices/addresses'
import { motion } from '../../../../motion'
import { BaseWrapper } from './base-wrapper'
import { Card, Form, Button } from './variants'

export const Address = ({ address, type }) => {
  const exists = useSelector(state => selectors.selectById(state, address) !== undefined)
  const mode = useSelector(state => selectors.selectById(state, address)?.mode)
  const isNew = useSelector(state => !selectors.selectById(state, address)?.id)
  const Component = withAddressComponent(mode)
  const props = { uuid: address, type }

  if (!exists) {
    return null
  }

  return (
    <motion.div
      layout
      className="address-wrapper__inner"
      variants={{
        hide: { opacity: 0 },
        show: { opacity: 1 },
      }}
      initial={'hide'}
      animate={'show'}
      exit={'hide'}
    >
      <motion.div animate={mode}>
        <BaseWrapper { ...props }>
          <AnimatePresence initial={false}>
            <Component { ...props }>
              {(buttonProps) => (
                <motion.button className={`address-button address-button--${mode}`} layoutId={`address-button-${type}-${address}-${isNew ? 'new' : 'new'}`} { ...buttonProps } />
              )}
            </Component>
          </AnimatePresence>
        </BaseWrapper>
      </motion.div>
    </motion.div>
  )
}

const withAddressComponent = (mode) => {
  switch (mode) {
  case 'card':
    return Card
  case 'button':
    return Button
  case 'form':
    return Form
  }
}
